import { FC, useContext, useEffect, useRef, useState } from 'react';
import { isInTripPageAtom, promotionsAtom } from '@/State/global/global.store';

import { CSSTransition } from 'react-transition-group';
import { HeaderData } from '@/Lib/types/header';
import HeaderMenuMain from '@/Components/header/header-menu-main';
import HeaderMenuTop from '@/Components/header/header-menu-top';
import { HeaderNavigation } from '@/Lib/types/header-navigation';
import { HeaderVisiblityContext } from 'layouts/MainLayout';
import PromotionBanner from '@/Components/promotion-banner/promotion-banner';
import { useAtomValue } from 'jotai';
import { useDevice } from '@/Lib/hooks/useDevice';
import { useIsGAppUserAgent } from '@/Lib/hooks/useIsGAppUserAgent';
import { useOffice } from '@/Lib/hooks/office/useOffice';
import useOnScreen from '@/Lib/hooks/useOnScreen';
import { useRouter } from 'next/router';

type props = {
  className?: string;
  navigationData: HeaderNavigation;
  headerData: HeaderData;
};

const Header: FC<props> = ({ navigationData, headerData }) => {
  const { isLG } = useDevice();
  const { isOpen } = useOffice();
  const router = useRouter();
  const headedrContext = useContext(HeaderVisiblityContext);
  const { height: promoHeight } = useAtomValue(promotionsAtom);
  const [headerAndPromoHeight, setHeaderAndPromoHeight] = useState<number>(58);
  const isGApp = useIsGAppUserAgent();

  const headerWrapperRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState<number>(58);
  const isInTripPage = useAtomValue(isInTripPageAtom);

  const endOfHeaderRef = useRef<HTMLDivElement>(null);
  const shouldHeaderBeVisible = useOnScreen(endOfHeaderRef);

  useEffect(() => {
    setHeaderAndPromoHeight(headerHeight + (promoHeight || 0));
  }, [headerHeight, promoHeight]);

  // NOTE: we render menu-top if office is open in mobile
  // so we should depend on the isOpen for updating the height
  useEffect(() => {
    setTimeout(() => {
      if (headerRef?.current) {
        setHeaderHeight(headerRef.current.getBoundingClientRect().height);
      }
    }, 250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerRef.current, isLG, isOpen]);

  useEffect(() => {
    if (!headedrContext) return;
    if (!isInTripPage) {
      headedrContext.setIsHeaderVisible(true);
      return;
    }

    headedrContext.setIsHeaderVisible(shouldHeaderBeVisible);
  }, [
    shouldHeaderBeVisible,
    headerAndPromoHeight,
    isInTripPage,
    headedrContext,
  ]);

  return (
    <div
      style={{
        paddingTop: `${headerAndPromoHeight}px`,
        transition: 'padding 0.3s ease-in-out',
      }}
      ref={endOfHeaderRef}
    >
      <CSSTransition
        in={headedrContext?.isHeaderVisible}
        timeout={250}
        classNames="header-fade"
        nodeRef={headerWrapperRef}
      >
        <div
          className={
            'z-300 text-base text-black fixed top-0 left-0 right-0 bg-white '
          }
          ref={headerWrapperRef}
        >
          {!isGApp ? <PromotionBanner /> : null}
          <div ref={headerRef}>
            <HeaderMenuTop headerData={headerData} />
            <HeaderMenuMain
              navigationData={navigationData}
              headerHeight={headerHeight}
            />
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Header;
