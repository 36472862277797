import { Input } from '@/Components/form-elements/Input/Input';
import { AppLink } from '@/Components/link/link';
import { ModalCentered } from '@/Components/modals/modal-centered';
import { RudderAnalytics } from '@/Components/ruddarstak/rudderanalytics';
import { SuccessAppToastIcon } from '@/Components/toast/app-toast-icon-set';
import {
  getNameValidation,
  getEmailValidation,
} from '@/Lib/helpers/validation';
import { useLocation } from '@/Lib/hooks/useLocation';
import { isEmailValid } from '@hapi/address/dist/email';
import { useState } from 'react';
import tlds from 'tlds';
import { Retention } from '@/Lib/helpers/retention';

const SubscribeForm = () => {
  const { location } = useLocation();
  const [showMessage, setShowMessage] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const onChangeEmail = (emailInput: string) => {
    if (!emailInput) {
      setEmailError('Email is required');
    } else if (emailInput && !isEmailValid(emailInput)) {
      setEmailError('Please provide a valid email address');
    } else if (
      email &&
      !isEmailValid(emailInput, { tlds: { allow: new Set(tlds) } })
    ) {
      setEmailError(`Please confirm this '${emailInput}' is your email. `);
    } else {
      setEmailError('');
    }
    setEmail(emailInput);
  };

  const onChangeName = (nameInput: string) => {
    if (!nameInput) {
      setNameError('Name is required');
    } else if (!getNameValidation(nameInput)) {
      setNameError('Please provide a valid name');
    } else {
      setNameError('');
    }
    setName(nameInput);
  };

  const subscribe = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    let hasError = false;
    if (!email) {
      setEmailError('Email is required');
      hasError = true;
    } else if (email && !isEmailValid(email)) {
      setEmailError('Please provide a valid email address');
      hasError = true;
    } else if (
      email &&
      !isEmailValid(email, { tlds: { allow: new Set(tlds) } })
    ) {
      setEmailError(`Please confirm this '${email}' is your email. `);
    } else {
      setEmailError('');
    }
    if (!name) {
      setNameError('Name is required');
      hasError = true;
    } else if (!getNameValidation(name)) {
      setNameError('Please provide a valid name');
      hasError = true;
    } else {
      setNameError('');
    }
    if (!hasError) {
      RudderAnalytics.newsletterSubscribed({
        country: location.country.name,
        email: email.trim() || null,
        platform: 'website',
        first_name: name.trim() || null,
      });
      RudderAnalytics.newUserRegisterration({
        country: location.country.name,
        email: email.trim(),
        platform: 'website',
        first_name: name.trim(),
      });
      setShowMessage(true);
      setIsOpenModal(false);
      Retention.suppress();
    }
  };

  const privacyPolicyClickHandler = () => {
    setIsOpenModal(false);
  };

  return (
    <>
      <button
        className={
          ' text-light-600 px-8 py-2.5 text-xl font-semibold rounded-md h-12 w-full md:w-9.375rem mt-5 ' +
          (showMessage ? ' bg-dark-500 cursor-auto ' : 'bg-teal-900')
        }
        onClick={() => !showMessage && setIsOpenModal(true)}
      >
        Subscribe
      </button>
      {showMessage && (
        <div className="mt-4 flex justify-center md:justify-start items-center">
          <SuccessAppToastIcon stroke="#0083A0" className="w-6 h-6" />
          <div className="ml-[0.313em] text-sm   !leading-6 font-medium text-teal-900">
            You are subscribed now
          </div>
        </div>
      )}
      <ModalCentered
        isModalOpen={isOpenModal}
        setIsModalOpen={setIsOpenModal}
        paddingClass="p-0"
        noCloseButton={true}
        hasPreventDefault={false}
        hasMdRoudedBorders={true}
        closeClickOut={false}
        zIndex={1001}
      >
        <div className="w-96vw max-w-full md:w-25rem md:min-w-25rem md:max-w-25rem p-5 md:p-1.875rem">
          <div className="">
            <div className="flex flex-col w-full items-center ">
              <div
                onClick={() => setIsOpenModal(false)}
                className="text-dark-900 text-sm !leading-6 font-extrabold cursor-pointer flex justify-end w-full "
              >
                <i className="icon-close font-extrabold "></i>
              </div>
              <div className="text-dark-900 text-lg md:text-2xl !leading-6 font-bold mt-2.5 ">
                Want to know a secret or two?
              </div>
            </div>
            <div className="mt-2.5 text-dark-600 text-base font-medium !leading-5 text-center">
              Join our inner circle of over 1 million to find out the tips &
              tricks of the better way to travel.
            </div>
          </div>
          <div className="mt-1.875rem flex flex-col justify-center">
            <div className="flex flex-col item-center">
              <div className="text-dark-700 !leading-4 text-sm font-medium">
                Email address
              </div>
              <Input
                changeHandler={(e: any) => {
                  onChangeEmail(e?.target?.value);
                }}
                placeholder=""
                name="email"
                value={email}
                classes=" !rounded-md mt-0.375rem "
                error={emailError}
                doesntHaveError={emailError ? false : true}
                errorBorder={true}
                errorClass=" !text-red-900 !text-2xs font-semibold"
              />
            </div>
            <div className="flex flex-col item-center mt-4">
              <div className="text-dark-700 !leading-4 text-sm font-medium">
                Name
              </div>
              <Input
                changeHandler={(e: any) => {
                  onChangeName(e?.target?.value);
                }}
                placeholder=""
                name="name"
                value={name}
                classes=" !rounded-md mt-0.375rem "
                error={nameError}
                doesntHaveError={nameError ? false : true}
                errorBorder={true}
                errorClass=" !text-red-900 !text-2xs font-semibold"
              />
            </div>
            <button
              className={
                ' text-light-600 h-12 px-8 py-2.5 text-xl font-medium rounded-md mt-4 bg-teal-900 '
              }
              onClick={(e: any) => subscribe(e)}
            >
              Subscribe
            </button>
            <p className="text-center my-2.5 text-2xs leading-4">
              Your info will be protected. It won’t be sold or spammed. Check
              out our&nbsp;
              <AppLink
                href={{
                  pathname: '/terms-and-conditions',
                  query: { tab: 'pp' },
                }}
                className="text-dark-700 cursor-pointer text-2xs leading-4"
                onClick={privacyPolicyClickHandler}
              >
                Privacy policy
              </AppLink>
              &nbsp;if you wish.
            </p>
          </div>
        </div>
      </ModalCentered>
    </>
  );
};
export { SubscribeForm };
