export const StorageKeys = {
  NotificationId: 'notificationID',
  VisitedPages: 'gwat-visited-pages',
  Donation: 'gawf_donation',
  Favorites: 'gwat-favorites',
  CU: 'gwat-cu',
  ProductCompares: 'gwat-compares',
  NotifyTrips: 'notify-trips',
  UserInfo: 'gwat-user',
};
